import React from "react";
import './buttons.scss';
import BaseButton from "./BaseButton";

export default function LinkButton({link, onClick, children}: {link:string, onClick?: Function, children: React.ReactNode}) {

    return (
        <a className={"button link-button"} href={link} onClick={() => {if(onClick) onClick()}}>
            <p>{children}</p>
        </a>
    )
}