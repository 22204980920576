import './modal.scss';
import WithHide from "../../../../util/WithHide";
import {useEffect} from "react";
import {MenuOption} from "../Option";
import Option from "./Option";

export default function Modal({id, parentId, options, isOpen, onClose}: {id: string, parentId: string, options: MenuOption[], isOpen: boolean, onClose: () => void}) {
    useEffect(() => {
        alignWithParent();
    }, []);

    function alignWithParent() {
        const parentEl = document.getElementById(parentId);
        const modalEl = document.getElementById(id);

        if(!parentEl) {
            console.error("cannot find parent for menu modal");
            return;
        }

        if(!modalEl) {
            console.error("cannot find menu modal");
            return;
        }

        const parentRect = parentEl.getBoundingClientRect();
        modalEl.style.left = `${parentRect.left - 10}px`;
        modalEl.style.top = `${parentRect.bottom + 10}px`;
    }

    function close() {
        onClose()
    }

    return (
        <WithHide isHide={!isOpen}>
            <div className={"menu-modal"} id={id}>
                {options?.map(o => <Option option={{title: o.title, link: o.link}} onClick={close} key={o.title}/>)}
            </div>
            <span className={"blur"} onClick={close}/>
        </WithHide>
    )
}