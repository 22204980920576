import Option from "./Option";
import {useLocation} from "react-router-dom";

export default function ContactUsOption() {
    const { pathname } = useLocation();

    function isSelected() {
        return pathname.includes("contact-us");
    }

    return <Option title={"Contact Us"} link={"/contact-us"} id={"contact-us-option"} isSelected={isSelected()}/>
}