import Page from "../Page";
import './landingPage.scss';
import Button from "../../forms/buttons/Button";
import LinkButton from "../../forms/buttons/LinkButton";

export default function LandingPage() {
    return (
        <Page id={"landing-page"}>
            <div className={"text-container"}>
                <h1 className={"title"}>Rare breed farming</h1>
                <h3 className={"subtitle"}>Raised with love, farmed with care</h3>
                <LinkButton link={"/contact-us"}>Place an order</LinkButton>
            </div>
            <div className={"bottom-panel"}/>
            <img className={"sheep-img"} alt={"black snooted sheep"} src={"/images/sheep.png"}/>
        </Page>
    )
}