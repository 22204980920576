import Page from "../../Page";
import LinkButton from "../../../forms/buttons/LinkButton";
import woolProducts from '../../../../data/woolProductData.json';
import './woolPage.scss';

export default function WoolPage() {
    return (
        <Page id={"what-we-sell-wool-page"}>
            <div className={"scrollable-wrapper"}>
                <div id={"data-block"}>
                    <h1 className={"title"}>Wool</h1>
                    <h3 className={"subtitle"}>Our wool is all natural and undyed, sheared from our very own sheep</h3>
                    {woolProducts.map(product => <Product title={product.title} price={product.price} subtitle={product.subtitle} key={product.title}/>)}
                    <LinkButton link={"/contact-us"}>Place an order</LinkButton>
                </div>
                <img src={"/images/wool.png"} className={"wool-img"}/>
            </div>
        </Page>
    )
}

function Product({title, subtitle, price}: {title: string, subtitle: string, price: string}) {
    return (
        <div className={"product-container"}>
            <div className={"product-text"}>
                <p className={"title"}>{title}</p>
                <p className={"subtitle"}>{subtitle}</p>
            </div>
            <p className={"price"}>{price}</p>
        </div>
    )
}