import LinkButton from "../../forms/buttons/LinkButton";
import './emailForm.scss';
import React, {useState} from "react";

export default function EmailForm() {

    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const recipientEmail = "morrellis.farm.supplier@gmail.com";

    const mailtoLink = `mailto:${recipientEmail}?subject=${subject}&body=${message}`;

    function onSubjectChange(event: React.FormEvent<HTMLInputElement>) {
        event.preventDefault();
        const value = event.currentTarget.value || "";
        setSubject(value);
    }

    function onMessageChange(event: React.FormEvent<HTMLTextAreaElement>) {
        event.preventDefault();
        const value = event.currentTarget.value || "";
        setMessage(value);
    }

    function onLinkClick() {
        setTimeout(() => {
            setSubject("");
            setMessage("");
        }, 500);
    }

    return (
        <div className={"email-form"}>
            <div className={"inputs"}>
                <input type={"text"} className={"subject-input input"} placeholder={"Subject"} onChange={onSubjectChange} value={subject}/>
                <textarea className={"message-input textarea input"} placeholder={"I would like to order..."} onChange={onMessageChange} value={message}/>
            </div>
            <LinkButton link={mailtoLink} onClick={onLinkClick}>Send</LinkButton>
        </div>
    )
}