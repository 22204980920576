import {useNavigate} from "react-router-dom";

export default function Logo() {
    const navigate = useNavigate();

    function onClick() {
        navigate("/");
    }

    return (
        <div className={"logo bob-clickable"} onClick={onClick}>
            <img src={"/images/sheep.png"}/>
        </div>
    )
}