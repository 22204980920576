import {MenuOption} from "../Option";
import cn from "classnames";
import {useLocation} from "react-router-dom";

export default function Option({option: {title, link}, onClick}: {option: MenuOption, onClick: () => void}) {
    const { pathname } = useLocation();
    const selectedClass = isSelected() ? "selected" : "unselected";

    function isSelected() {
        return pathname.includes(link);
    }

    return (
        <div className={"menu-modal-option"}>
            <a href={link} className={cn("bob-clickable option", selectedClass)} onClick={onClick}>{title}</a>
        </div>
    )
}