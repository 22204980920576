import Page from "../../Page";
import LinkButton from "../../../forms/buttons/LinkButton";
import meatProducts from '../../../../data/meatProductData.json';
import './meatPage.scss';

export default function MeatPage() {
    return (
        <Page id={"what-we-sell-meat-page"}>
            <div className={"scrollable-wrapper"}>
                <div id={"data-block"}>
                    <h1 className={"title"}>Meat</h1>
                    <h3 className={"subtitle"}>Our specialty high welfare, grass fed ryeland lamb, hogget and mutton</h3>
                    {meatProducts.map(product => <Product title={product.title} price={product.price} key={product.title}/>)}
                    <LinkButton link={"/contact-us"}>Place an order</LinkButton>
                </div>
                <img src={"/images/meat.png"} className={"meat-img"}/>
            </div>
        </Page>
    )
}

function Product({title, price}: {title: string, price: string}) {
    return (
        <div className={"product-container"}>
            <p className={"title"}>{title}</p>
            <p className={"price"}>{price}</p>
        </div>
    )
}