import "./navbar.scss";
import Logo from "./Logo";
import WhatWeSellOption from "./options/WhatWeSellOption";
import WhoWeAreOption from "./options/WhoWeAreOption";
import ContactUsOption from "./options/ContactUsOption";

export default function Navbar() {
    return (
        <div id={"navbar"}>
            <Logo/>
            <h1 className={"title primary-font"}>Morrelli's Farm</h1>
            <WhatWeSellOption/>
            <WhoWeAreOption/>
            <ContactUsOption/>
        </div>
    );
}