import LandingPage from "./LandingPage";
import ContactUsPage from "./ContactUsPage";
import {Route, Routes} from "react-router-dom";
import WhoWeArePage from "./WhoWeArePage";
import MeatPage from "./whatWeSell/MeatPage";
import WoolPage from "./whatWeSell/WoolPage";

export function WideScreenApp() {
    return (
        <div className={"widescreen-app"}>
            <Routes>
                <Route path={"/"} element={<LandingPage/>}/>
                <Route path={"/contact-us"} element={<ContactUsPage/>}/>
                <Route path={"/who-we-are"} element={<WhoWeArePage/>}/>
                <Route path={"/what-we-sell/meat"} element={<MeatPage/>}/>
                <Route path={"/what-we-sell/wool"} element={<WoolPage/>}/>
            </Routes>
        </div>
    )
}