import Page from "../Page";
import './whoWeArePage.scss';

export default function WhoWeArePage() {
    return (
        <Page id={"who-we-are-page"}>
            <div className={"scrollable-wrapper"}>
                <h1 className={"title"}>Who We Are</h1>
                <article className={"text"}>
                <span>
                Here in beautiful west Wales, we benefit from fresh rain travelling over the Pressilli mountain range, to nourish our pastures.
                Minerals feed onto our dense grasslands, stitched in with timothy grass, red clover, cat's ear, fescues, selfueal, common eyesbright and yorkshire fog.
                </span>
                    <span>
                Our heritage and rare breeds thrive on such lush forage, ensuring grass feo, high welfare, beef, lamb, nogget, mutton, pork and chicken for local market.
                </span>
                    <span>
                    Our pedigree Ryeland, Ouessant and Babydolls, give as much back to the land as they benefit from it. As a "closed flock" we use natural worm and parasite
                    control. The sheep are fed treats of apple, shelter under oak trees and have free and open access to field shelters.
                </span>
                    <span>
                    Every day they are petted, in a calm,
                     respectful environment. Such care and compassion is evident in the taste of our meat.
                </span>

                </article>
            </div>
        </Page>
    )
}