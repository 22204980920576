import Navbar from "../Navbar";
import React from "react";
import Footer from "../Footer";
import './page.scss';

export default function Page({id, children}: {id: string, children: React.ReactNode}) {
    return (
        <div className={"page"} id={id}>
            <Navbar/>
            <div className={"content"}>
                {children}
            </div>
            <Footer/>
        </div>
    )
}