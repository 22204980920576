import React from 'react';
import {WideScreenApp} from "./components/WideScreenApp";

function App() {
  return (
    <div className="App">
      <WideScreenApp/>
    </div>
  );
}

export default App;
