import Page from "../Page";
import EmailForm from "./EmailForm";
import './contactUsPage.scss';

export default function ContactUsPage() {
    return (
        <Page id={"contact-us-page"}>
            <h1 className={"title"}>Contact Us</h1>
            <h3 className={"subtitle"}>We're always happy to answer questions and take new orders!</h3>
            <EmailForm/>
        </Page>
    )
}