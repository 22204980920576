import Option from "./Option";
import {useLocation} from "react-router-dom";

export default function WhoWeAreOption() {
    const { pathname } = useLocation();

    function isSelected() {
        return pathname.includes("who-we-are");
    }

    return <Option title={"Who We Are"} link={"/who-we-are"} id={"who-we-are-option"} isSelected={isSelected()}/>
}