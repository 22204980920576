import {useNavigate} from "react-router-dom";
import cn from "classnames";
import Modal from "./Modal";
import {useState} from "react";
import WithHide from "../../../util/WithHide";

export type MenuOption = {
    title: string;
    link: string
};

export default function Option({title, link, isSelected, options, id}: {title: string, link?: string, isSelected?: boolean, options?: MenuOption[], id: string}) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const selectedClass = isSelected ? "selected" : "unselected";

    function onClick() {
        if(link && !isSelected) navigate(link);
        if(options) {
            setIsMenuOpen(true);
        }
    }

    function onMenuClose() {
        setIsMenuOpen(false);
    }

    return (
        <div className={cn("option-container", selectedClass)} id={id}>
            <h2 className={"option bob-clickable primary-font"} onClick={onClick}>{title}</h2>
            <WithHide isHide={!options || !isMenuOpen}>
                <Modal options={options || []} id={`${id}-modal`} parentId={id} isOpen={isMenuOpen} onClose={() => {onMenuClose()}}/>
            </WithHide>
        </div>
    )
}